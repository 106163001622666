import { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog';
import { Project, ProjectFile, ProjectStatus } from '@/types/project';
import { downloadFile, updateProjectStatus } from '@/lib/project-service';
import { useAuth } from '@/contexts/AuthContext';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import { FileDown, FileText } from 'lucide-react';
import { formatDate, formatFileSize } from '@/lib/utils';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

interface ProjectDetailsDialogProps {
  project: Project | null;
  isOpen: boolean;
  onClose: () => void;
  setProjects: React.Dispatch<React.SetStateAction<Project[]>>;
}

export const ProjectDetailsDialog = ({ project, isOpen, onClose, setProjects }: ProjectDetailsDialogProps) => {
  const [loading, setLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const { user } = useAuth();

  const handleDownload = async (file: ProjectFile) => {
    try {
      setLoading(true);
      const blob = await downloadFile(file.path);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading file:', error);
      toast.error('Erro ao baixar arquivo');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (newStatus: ProjectStatus) => {
    if (!project) return;

    if (user?.role !== 'admin') {
      toast.error('Apenas administradores podem alterar o status');
      return;
    }

    try {
      setStatusLoading(true);
      await updateProjectStatus(project.reference, newStatus);
      
      // Update local state
      setProjects(prevProjects => prevProjects.map(p => 
        p.reference === project.reference 
          ? { ...p, status: newStatus }
          : p
      ));
      
      toast.success('Status atualizado com sucesso');
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Erro ao atualizar status');
    } finally {
      setStatusLoading(false);
    }
  };

  const statusColors = {
    'Enviado': 'bg-blue-500/20 text-blue-300 border-blue-500/50',
    'Em Análise': 'bg-yellow-500/20 text-yellow-300 border-yellow-500/50',
    'Concluído': 'bg-green-500/20 text-green-300 border-green-500/50',
    'Cancelado': 'bg-red-500/20 text-red-300 border-red-500/50'
  };

  if (!project) return null;

  const isAdmin = user?.role === 'admin' && window.location.pathname.includes('/admin');

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl max-h-[90vh] overflow-y-auto bg-black/95 border-white/10">
        <DialogHeader className="sticky top-0 bg-black pb-4 z-10">
          <DialogTitle className="text-xl font-semibold">
            {project.submissionDetails?.name || 'Sem nome'}
          </DialogTitle>
          <DialogDescription className="text-white/70 space-y-1">
            <div className="flex items-center gap-2">
              <Badge variant="outline" className={cn(statusColors[project.status as keyof typeof statusColors])}>
                {project.status}
              </Badge>
              <span>•</span>
              <span>{formatDate(project.submittedAt || project.createdAt)}</span>
            </div>
            <div>
              Referência: <code className="bg-white/5 px-2 py-1 rounded">{project.reference}</code>
            </div>
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-6">
          {/* Project Reference */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium">Referência do Projeto</h3>
            <div className="flex items-center gap-3 bg-white/5 p-3 rounded-lg">
              <FileText className="w-5 h-5 text-white/40" />
              <div>
                <p className="text-sm text-white/40">Referência</p>
                <p className="font-medium">{project.reference || 'N/A'}</p>
              </div>
            </div>
          </div>

          {/* Client Information */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium">Informações do Cliente</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 md:gap-4">
              <div className="flex items-center gap-3 bg-white/5 p-3 rounded-lg">
                <FileText className="w-5 h-5 text-white/40" />
                <div>
                  <p className="text-sm text-white/40">Nome</p>
                  <p className="font-medium">{project.submissionDetails?.name || 'N/A'}</p>
                </div>
              </div>
              <div className="flex items-center gap-3 bg-white/5 p-3 rounded-lg">
                <FileText className="w-5 h-5 text-white/40" />
                <div>
                  <p className="text-sm text-white/40">Email</p>
                  <p className="font-medium break-all">{project.submissionDetails?.email || 'N/A'}</p>
                </div>
              </div>
              <div className="flex items-center gap-3 bg-white/5 p-3 rounded-lg">
                <FileText className="w-5 h-5 text-white/40" />
                <div>
                  <p className="text-sm text-white/40">Telefone</p>
                  <p className="font-medium">{project.submissionDetails?.phone || 'N/A'}</p>
                </div>
              </div>
              <div className="flex items-center gap-3 bg-white/5 p-3 rounded-lg">
                <FileText className="w-5 h-5 text-white/40" />
                <div>
                  <p className="text-sm text-white/40">CNPJ/CPF</p>
                  <p className="font-medium">{project.submissionDetails?.documentNumber || 'N/A'}</p>
                </div>
              </div>
              <div className="flex items-center gap-3 bg-white/5 p-3 rounded-lg col-span-2">
                <FileText className="w-5 h-5 text-white/40" />
                <div className="w-full">
                  <p className="text-sm text-white/40">Perfil</p>
                  <div className="font-medium">
                    {project.submissionDetails?.profile || 'N/A'}
                  </div>
                  {project.submissionDetails?.profile === 'Outro' && (
                    <div className="mt-2 bg-white/5 p-2 rounded">
                      <p className="text-sm text-white/40">Especifique seu perfil</p>
                      <p className="font-medium text-white/90">
                        {project.submissionDetails?.customProfile || 'Visio'}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Project Details */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium">Detalhes do Projeto</h3>
            <div className="bg-white/5 p-3 md:p-4 rounded-lg space-y-4">
              <div>
                <p className="text-sm text-white/40 mb-1">Descrição</p>
                <p className="text-white/90 whitespace-pre-wrap">{project.submissionDetails?.description || 'N/A'}</p>
              </div>
              <div>
                <p className="text-sm text-white/40 mb-2">Entregáveis Solicitados</p>
                <div className="flex flex-wrap gap-2">
                  {project.submissionDetails?.deliverables?.map((item: string, index: number) => (
                    <div key={index} className="flex items-center gap-2 bg-white/10 px-3 py-1.5 rounded-lg">
                      <FileText className="w-4 h-4 text-white/40" />
                      <span className="text-sm">{item}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Status Update (Admin Only) */}
          {isAdmin && (
            <div className="space-y-4">
              <h3 className="text-lg font-medium">Alterar Status</h3>
              <Select
                value={project.status}
                onValueChange={handleStatusChange}
                disabled={statusLoading}
              >
                <SelectTrigger className="w-full bg-white/5">
                  <SelectValue placeholder="Selecione o status" />
                </SelectTrigger>
                <SelectContent>
                  {(Object.keys(statusColors) as ProjectStatus[]).map(status => (
                    <SelectItem key={status} value={status}>
                      {status}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )}

          {/* Files */}
          {project.files?.length > 0 && (
            <div className="space-y-4">
              <h3 className="text-lg font-medium">Arquivos Anexados</h3>
              <div className="grid gap-3">
                {project.files.map((file: ProjectFile, index: number) => (
                  <div
                    key={index}
                    className="flex flex-col sm:flex-row sm:items-center justify-between gap-3 bg-white/5 p-3 rounded-lg"
                  >
                    <div className="flex items-center gap-3">
                      <FileText className="w-5 h-5 text-white/40" />
                      <div className="min-w-0">
                        <p className="font-medium truncate">{file.name}</p>
                        <p className="text-sm text-white/40">{formatFileSize(Number(file.size))}</p>
                      </div>
                    </div>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => handleDownload(file)}
                      disabled={loading}
                      className="hover:bg-white/10 w-full sm:w-auto"
                    >
                      <FileDown className="w-4 h-4 mr-2" />
                      Baixar
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
