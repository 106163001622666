import { motion } from 'framer-motion';
import { useContentStore } from '@/stores/contentStore';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { cn } from '@/lib/utils';

export default function FAQ() {
  const { faq } = useContentStore();

  if (!faq) return null;

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Hero Section */}
      <section
        className="relative min-h-[60vh] flex items-center justify-center bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url('/assets/faq.jpg')` }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/50 to-transparent" />

        <div className="relative z-10 text-center max-w-6xl mx-auto px-4 mt-32 md:mt-16">
          <h1 
            className="text-[2.75rem] md:text-[3.5rem] leading-tight font-bold mb-3 max-w-6xl mx-auto text-white
              drop-shadow-[0_1px_1px_rgba(75,75,75,0.8)]
              [text-shadow:_0_1px_0_rgb(75_75_75_/_40%),_0_2px_0_rgb(75_75_75_/_30%),_0_3px_0_rgb(75_75_75_/_20%)]"
          >
            {faq.title}
          </h1>
          <p
            className="text-xl md:text-2xl text-white/90 max-w-6xl mx-auto mt-6
              drop-shadow-[0_1px_1px_rgba(75,75,75,0.8)]
              [text-shadow:_0_1px_0_rgb(75_75_75_/_40%)]"
          >
            {faq.subtitle}
          </p>
        </div>
      </section>

      {/* FAQ Section with Glass Effect */}
      <section className="relative z-10 -mt-20 pb-20">
        <div className="container max-w-6xl mx-auto px-4">
          {/* Glowing background effect */}
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-5xl h-[120%] blur-[100px] bg-gradient-to-b from-white/20 via-white/5 to-transparent rounded-full" />
          
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="relative backdrop-blur-xl bg-white/95 border border-white/40 rounded-2xl p-8 shadow-[0_0_50px_rgba(255,255,255,0.15)] overflow-hidden"
          >
            {/* Inner glow */}
            <div className="absolute inset-0 bg-gradient-radial from-white to-transparent opacity-30 pointer-events-none" />
            
            {/* Title */}
            <div className="relative z-10 text-center mb-8">
              <h2 className="text-3xl font-bold text-gray-600 inline-block relative">
                FAQ
                <div className="absolute inset-0 blur-sm bg-gray-400/30 -z-10" aria-hidden="true" />
              </h2>
            </div>
            
            <Accordion type="single" collapsible className="w-full space-y-4 relative z-10">
              {faq.faqs?.map((item, index) => (
                <AccordionItem
                  key={index}
                  value={`item-${index}`}
                  className="border border-black/10 rounded-lg bg-white backdrop-blur-sm overflow-hidden"
                >
                  <AccordionTrigger className="text-lg font-medium text-gray-700 hover:text-gray-900 px-6 py-4 transition-colors duration-200">
                    {item.question}
                  </AccordionTrigger>
                  <AccordionContent className="px-6 pb-6">
                    {item.answer.split('\n').map((paragraph, idx) => (
                      <p key={idx} className="mt-2 whitespace-pre-line text-gray-600">
                        {paragraph}
                      </p>
                    ))}
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </motion.div>
        </div>
      </section>
    </div>
  );
}