import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Mail, Phone, MapPin, ArrowRight } from 'lucide-react';
import { useContentStore } from '@/stores/contentStore';
import { motion } from 'framer-motion';
import { useEffect } from 'react';

const Contact = () => {
  const { contact } = useContentStore();

  useEffect(() => {
    // Hide WhatsApp floating button on mount
    const whatsappButton = document.querySelector('[data-whatsapp-float]');
    if (whatsappButton) {
      whatsappButton.classList.add('hidden');
    }

    // Show WhatsApp floating button on unmount
    return () => {
      if (whatsappButton) {
        whatsappButton.classList.remove('hidden');
      }
    };
  }, []);

  if (!contact) return null;

  return (
    <div className="min-h-screen text-white">
      {/* Hero Section */}
      <section
        className="relative min-h-[60vh] flex items-center justify-center bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url('/assets/contact.jpg')` }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/50 to-transparent" />

        <div className="relative z-10 text-center max-w-6xl mx-auto px-4 mt-32 md:mt-16">
          <h1 
            className="text-[2.75rem] md:text-[3.5rem] leading-tight font-bold mb-3 max-w-6xl mx-auto text-white
              drop-shadow-[0_1px_1px_rgba(75,75,75,0.8)]
              [text-shadow:_0_1px_0_rgb(75_75_75_/_40%),_0_2px_0_rgb(75_75_75_/_30%),_0_3px_0_rgb(75_75_75_/_20%)]"
          >
            Olá, Partner! 👋
          </h1>
          <p
            className="text-xl md:text-2xl text-white/90 max-w-6xl mx-auto mt-6
              drop-shadow-[0_1px_1px_rgba(75,75,75,0.8)]
              [text-shadow:_0_1px_0_rgb(75_75_75_/_40%)]"
          >
            {contact?.subtitle || "Como podemos te ajudar?"}
          </p>
          <p className="text-white/70 mt-4 max-w-6xl mx-auto">
            {contact.description}
          </p>
        </div>
      </section>

      {/* Contact Info Section */}
      <div className="max-w-7xl mx-auto px-8 -mt-32 relative z-10 mb-24">
        <div className="grid md:grid-cols-3 gap-8 mb-12">
          <div className="bg-black/40 backdrop-blur-xl border border-white/10 rounded-2xl p-8 shadow-[0_8px_32px_rgba(255,255,255,0.1)] flex flex-col items-center text-center">
            <div className="text-4xl mb-4">📧</div>
            <h3 className="text-xl font-semibold mb-2 text-white">E-mail</h3>
            <p className="text-white/90">contato@visiopartners.com.br</p>
            <p className="text-white/70">{contact.email}</p>
          </div>

          <div className="bg-black/40 backdrop-blur-xl border border-white/10 rounded-2xl p-8 shadow-[0_8px_32px_rgba(255,255,255,0.1)] flex flex-col items-center text-center">
            <div className="text-4xl mb-4">📱</div>
            <h3 className="text-xl font-semibold mb-2 text-white">Telefone</h3>
            <p className="text-white/90">(31) 99398-4114</p>
            <p className="text-white/70">{contact.phone}</p>
          </div>

          <div className="bg-black/40 backdrop-blur-xl border border-white/10 rounded-2xl p-8 shadow-[0_8px_32px_rgba(255,255,255,0.1)] flex flex-col items-center text-center">
            <div className="text-4xl mb-4">📍</div>
            <h2 className="text-xl font-semibold mb-2 text-white">Endereço</h2>
            <p className="text-white/90">Belo Horizonte, MG</p>
            <p className="text-white/70">{contact.address}</p>
          </div>
        </div>

        {/* Vamos Conversar Section */}
        <div className="flex justify-center">
          <div className="relative bg-emerald-600 rounded-2xl p-8 overflow-hidden w-full md:w-1/3
            shadow-[0_0_40px_rgba(16,185,129,0.3)]
            border border-emerald-400">
            {/* Glow effects */}
            <div className="absolute inset-0 bg-gradient-to-b from-emerald-500/50 to-emerald-700/50" />
            <div className="absolute -inset-[100px] bg-emerald-500/20 blur-[100px]" />
            
            {/* Content */}
            <div className="relative z-10 text-center mb-8">
              <h2 className="text-3xl font-bold text-white inline-block relative">
                Vamos Conversar?
                <div className="absolute inset-0 blur-sm bg-white/20 -z-10" aria-hidden="true" />
              </h2>
              <p className="text-white/90 mt-4">
                Estamos prontos para ajudar você a transformar suas ideias em realidade.
              </p>
            </div>

            {/* Button */}
            <div className="flex justify-center relative z-10">
              <Button 
                asChild
                className="group relative overflow-hidden px-8 py-4 w-auto max-w-[280px]
                  bg-white/90 hover:bg-white 
                  shadow-[0_8px_32px_rgba(255,255,255,0.4)]
                  hover:shadow-[0_8px_32px_rgba(255,255,255,0.6)]
                  transition-all duration-300"
              >
                <a href="https://api.whatsapp.com/send?phone=5531993984114" target="_blank" rel="noopener noreferrer" className="flex justify-center items-center">
                  <span className="flex items-center gap-2 text-sm font-medium text-black whitespace-nowrap">
                    Iniciar conversa Whatsapp
                    <ArrowRight className="w-4 h-4 transition-transform group-hover:translate-x-1" />
                  </span>
                  <div className="absolute inset-0 bg-gradient-to-r from-white/0 via-emerald-50/5 to-white/0 
                    group-hover:via-emerald-50/10 blur-xl transition-opacity opacity-0 group-hover:opacity-100" />
                </a>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;