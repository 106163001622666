import { useState } from 'react';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { X } from 'lucide-react';
import { motion } from 'framer-motion';
import { useContentStore } from '@/stores/contentStore';
import type { NewsItem } from '@/types/content';

// Import default news images
import defaultNewsImage from '@/assets/images/home.jpg';

const formatDate = (date: Date) => {
  return date.toISOString().split('T')[0];
};

const News = () => {
  const [selectedNews, setSelectedNews] = useState<NewsItem | null>(null);
  const { news } = useContentStore();

  if (!news || news.length === 0) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-4xl font-bold mb-4">Notícias</h1>
          <p className="text-xl mb-8">Carregando...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black">
      {/* Hero Section */}
      <section className="pt-40 pb-16 relative">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,_var(--tw-gradient-stops))] from-white/[0.03] to-transparent" />
        
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 
            className="text-[2.75rem] md:text-[3.5rem] leading-tight font-bold mb-3 max-w-6xl mx-auto text-white text-center
              drop-shadow-[0_1px_1px_rgba(75,75,75,0.8)]
              [text-shadow:_0_1px_0_rgb(75_75_75_/_40%),_0_2px_0_rgb(75_75_75_/_30%),_0_3px_0_rgb(75_75_75_/_20%)]"
          >
            Notícias
          </h1>
          <p className="text-xl md:text-2xl text-white/90 max-w-3xl mx-auto text-center mb-16">
            Fique por dentro das últimas novidades e inovações no setor da construção civil
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {Array.isArray(news) && news.map((item, index) => (
              <motion.div
                key={item.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="relative group"
                onClick={() => setSelectedNews(item)}
              >
                {/* Glow effect */}
                <div className="absolute -inset-1 bg-white/20 rounded-2xl blur-xl group-hover:bg-white/30 transition-colors duration-500" />
                
                {/* Glass card */}
                <div className="relative p-6 sm:p-8 rounded-xl bg-white/10 backdrop-blur-lg
                  transform hover:scale-105 transition-all duration-300 cursor-pointer
                  shadow-[0_8px_32px_rgba(255,255,255,0.1)]
                  group-hover:shadow-[0_8px_32px_rgba(255,255,255,0.2)]
                  before:absolute before:inset-0 before:rounded-xl before:border-t before:border-l before:border-white/20 before:-z-[1] before:translate-y-0.5
                  after:absolute after:inset-0 after:rounded-xl after:border-b after:border-r after:border-black/20 after:-z-[1] after:-translate-y-0.5
                  border border-white/5 h-full"
                >
                  <div className="flex flex-col h-full">
                    {/* Image */}
                    <div className="relative w-full h-48 mb-4 rounded-lg overflow-hidden">
                      <img
                        src={item.image || defaultNewsImage}
                        alt={item.title}
                        className="absolute inset-0 w-full h-full object-cover"
                      />
                    </div>
                    
                    {/* Content */}
                    <div className="flex-grow">
                      <div className="flex items-center gap-2 mb-2">
                        <span className="text-sm text-white/60 px-2 py-1 rounded-full bg-white/10">
                          {item.category}
                        </span>
                        <span className="text-sm text-white/60">
                          {item.readTime}
                        </span>
                      </div>
                      <h3 className="text-xl font-semibold mb-2 text-white line-clamp-2">{item.title}</h3>
                      <p className="text-white/80 mb-4 line-clamp-3">{item.subtitle}</p>
                    </div>
                    
                    {/* Footer */}
                    <div className="flex justify-between items-center text-sm text-white/60">
                      <span>{formatDate(new Date(item.date))}</span>
                      <span>{item.author}</span>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* News Modal */}
      <Dialog open={!!selectedNews} onOpenChange={() => setSelectedNews(null)}>
        <DialogContent className="max-w-[95vw] md:max-w-3xl max-h-[90vh] overflow-y-auto bg-black/95 border-white/10 p-4 sm:p-6">
          <DialogHeader className="mb-4">
            <DialogTitle className="text-xl sm:text-2xl font-bold text-white">
              {selectedNews?.title}
            </DialogTitle>
            <Button
              variant="ghost"
              className="absolute right-2 sm:right-4 top-2 sm:top-4 text-white/60 hover:text-white"
              onClick={() => setSelectedNews(null)}
            >
              <X className="h-4 w-4" />
            </Button>
          </DialogHeader>
          
          <div className="space-y-6">
            {/* Image */}
            <div className="relative w-full h-48 sm:h-64 rounded-lg overflow-hidden">
              <img
                src={selectedNews?.image || defaultNewsImage}
                alt={selectedNews?.title}
                className="absolute inset-0 w-full h-full object-cover"
              />
            </div>
            
            {/* Content */}
            <div className="flex flex-wrap items-center gap-3">
              <span className="text-sm text-white/60 px-3 py-1 rounded-full bg-white/10">
                {selectedNews?.category}
              </span>
              <span className="text-sm text-white/60">
                {selectedNews?.readTime}
              </span>
            </div>
            
            <p className="text-base sm:text-lg text-white/80">{selectedNews?.subtitle}</p>
            
            <div className="prose prose-invert max-w-none">
              {selectedNews?.content.split('\n').map((paragraph, idx) => (
                <p key={idx} className="text-sm sm:text-base text-white/80 whitespace-pre-line mb-4">
                  {paragraph.trim()}
                </p>
              ))}
            </div>
            
            <div className="pt-6 border-t border-white/10 flex flex-wrap justify-between items-center gap-4 text-sm text-white/60">
              <span>{selectedNews && formatDate(new Date(selectedNews.date))}</span>
              <span>{selectedNews?.author}</span>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default News;