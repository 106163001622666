import api from './api';
import { Project } from '../types/project'; // Corrected path

interface ProjectSubmissionData {
  name: string;
  email: string;
  phone: string;
  documentNumber: string;
  profile: string;
  customProfile?: string;
  description: string;
  deliverables: string[];
  files: File[];
  type: string;
}

interface ProjectResponse {
  success: boolean;
  project?: Project;
  reference?: string;
}

export const submitProject = async (data: ProjectSubmissionData): Promise<ProjectResponse> => {
  try {
    const formData = new FormData();
    
    const projectData = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      documentNumber: data.documentNumber,
      profile: data.profile,
      customProfile: data.customProfile,
      description: data.description,
      deliverables: data.deliverables,
      type: data.type
    };
    
    formData.append('projectData', JSON.stringify(projectData));
    
    data.files.forEach((file) => {
      formData.append('files', file);
    });

    const response = await api.post('/projects', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.data.success && response.data.reference) {
      return {
        ...response.data,
        reference: response.data.reference
      };
    }

    return response.data;
  } catch (error: any) {
    console.error('Project submission error:', error);
    throw new Error('Erro ao enviar projeto. Por favor, tente novamente.');
  }
};

export const submitProjectNonLogged = async (data: ProjectSubmissionData): Promise<ProjectResponse> => {
  try {
    const formData = new FormData();
    
    // Convert project data to a JSON string, excluding files
    const projectData = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      documentNumber: data.documentNumber,
      profile: data.profile,
      customProfile: data.customProfile || '',
      description: data.description,
      deliverables: data.deliverables,
      type: data.type
    };
    
    formData.append('projectData', JSON.stringify(projectData));
    
    // Append each file with a unique index
    if (data.files && data.files.length > 0) {
      data.files.forEach((file, index) => {
        formData.append(`file${index}`, file);
      });
    }

    const response = await api.post('/projects/submit', formData);

    if (!response.data) {
      throw new Error('Resposta inválida do servidor');
    }

    return response.data;
  } catch (error: any) {
    console.error('Project submission error:', error);
    if (error.response?.data?.error) {
      throw new Error(error.response.data.error);
    }
    throw new Error('Erro ao enviar projeto. Por favor, tente novamente.');
  }
};

export const getProjects = async (userId?: string, email?: string): Promise<Project[]> => {
  try {
    const params = new URLSearchParams();
    if (userId) params.append('userId', userId);
    if (email) params.append('email', email);
    
    const response = await api.get(`/projects?${params.toString()}`);
    
    if (!Array.isArray(response.data)) {
      console.error('Invalid response format:', response.data);
      return [];
    }
    
    return response.data; // Ensure this function returns the project array
  } catch (error: any) {
    console.error('Error getting projects:', error);
    return [];
  }
};

export const updateProjectStatus = async (reference: string, status: string): Promise<void> => {
  try {
    await api.put(`/projects/${reference}/status`, { status });
  } catch (error) {
    console.error('Project status update error:', error);
    throw new Error('Erro ao atualizar status do projeto. Por favor, tente novamente.');
  }
};

export const downloadFile = async (filePath: string): Promise<Blob> => {
  try {
    const response = await api.get(`/download/${filePath}`, {
      responseType: 'blob'
    });
    return response.data;
  } catch (error) {
    console.error('File download error:', error);
    throw new Error('Erro ao baixar arquivo. Por favor, tente novamente.');
  }
};

export async function updateProjectUserId(reference: string, userId: string): Promise<void> {
  await api.patch(`/projects/${reference}/user`, { userId });
  return; // Ensure this function returns void
}

export default {
  submitProject,
  submitProjectNonLogged,
  getProjects,
  updateProjectStatus,
  downloadFile,
  updateProjectUserId,
};
