import React from 'react';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { ArrowRight, Building2, BarChart3, LineChart } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useContentStore } from '@/stores/contentStore';
import { ScrollArrow } from '@/components/ScrollArrow';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { cn } from '@/lib/utils';

const iconMap = {
  Building2,
  BarChart3,
  LineChart
};

const Home = () => {
  const { home } = useContentStore();

  if (!home) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-4xl font-bold mb-4">Visio Partners</h1>
          <p className="text-xl mb-8">Carregando...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <section
        className="relative min-h-screen flex items-center justify-center bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url('/assets/home.jpg')` }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/50 to-black" />

        <div className="relative z-10 text-center max-w-6xl mx-auto px-4 mt-32 md:mt-16">
          <h1 
            className="text-[2rem] md:text-[2.75rem] leading-tight font-bold text-white mb-6"
          >
            {home.mainTitle}
          </h1>
          <p 
            className="text-xl text-white/90 max-w-4xl mx-auto mb-12"
          >
            {home.subtitle}
          </p>
          <div className="relative flex justify-center items-center mb-24">
            {/* Glowing background effect */}
            <div className="absolute -inset-8 bg-white/10 rounded-full blur-3xl opacity-75 animate-pulse transition-opacity duration-500" />
            
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              animate={{ 
                y: [0, -8, 0],
                transition: { 
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut" 
                }
              }}
              className="relative flex items-center justify-center"
            >
              <Button
                size="lg"
                className="relative bg-white/90 backdrop-blur-sm
                  text-black hover:bg-white transition-all duration-300
                  shadow-[0_0_40px_rgba(255,255,255,0.4)]
                  hover:shadow-[0_0_60px_rgba(255,255,255,0.6)]
                  text-xl py-8 px-12 rounded-[2rem] font-semibold
                  border border-white/40
                  overflow-hidden
                  transform hover:scale-105 active:scale-95"
              >
                <Link to="/submit-project" className="flex items-center gap-4 relative z-10 whitespace-nowrap">
                  Envie seu Projeto <ArrowRight className="w-6 h-6 ml-2" strokeWidth={2.5} />
                </Link>
              </Button>
            </motion.div>
          </div>
        </div>

        <ScrollArrow />
      </section>

      {/* Stats Section */}
      <section className="min-h-[90vh] bg-black relative">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,_var(--tw-gradient-stops))] from-white/[0.03] to-transparent" />
        
        {/* Content wrapper for vertical centering */}
        <div className="absolute inset-0 flex items-center">
          <div className="w-full max-w-7xl mx-auto px-8">
            <h2 className="text-3xl font-bold text-center mb-16 text-white">
              {home.statsTitle}
            </h2>
            
            <div className="grid md:grid-cols-3 gap-8">
              {home.stats?.map((stat, index) => (
                <div key={index} className="relative group">
                  {/* Glow effect */}
                  <div className="absolute -inset-1 bg-white/20 rounded-2xl blur-xl group-hover:bg-white/30 transition-colors duration-500" />
                  
                  {/* Glass card */}
                  <div className="relative p-8 rounded-xl bg-white/10 backdrop-blur-lg
                    transform hover:scale-105 transition-all duration-300
                    shadow-[0_8px_32px_rgba(255,255,255,0.1)]
                    group-hover:shadow-[0_8px_32px_rgba(255,255,255,0.2)]
                    before:absolute before:inset-0 before:rounded-xl before:border-t before:border-l before:border-white/20 before:-z-[1] before:translate-y-0.5
                    after:absolute after:inset-0 after:rounded-xl after:border-b after:border-r after:border-black/20 after:-z-[1] after:-translate-y-0.5
                    border border-white/5 h-full">
                    <div className="flex flex-col items-center gap-4">
                      {stat.icon && iconMap[stat.icon as keyof typeof iconMap] && (
                        <div className="p-4 rounded-full bg-white/5">
                          {React.createElement(iconMap[stat.icon as keyof typeof iconMap], {
                            className: "w-8 h-8 text-white"
                          })}
                        </div>
                      )}
                      <h3 className="text-4xl font-bold text-white">{stat.value}</h3>
                      <p className="text-lg text-white/90">{stat.label}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;