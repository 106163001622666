import { createContext, useContext, ReactNode } from 'react';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { login } from '@/lib/api';
import { initDB } from '@/lib/db'; // Import initDB function
import api from '@/lib/axios'; // Import axios instance

interface User {
  uid: string;
  id: string;
  name: string;
  email: string | null;
  role?: 'user' | 'admin';
  phone?: string;
  company?: string;
  createdAt: string;
}

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<User>;
  logout: () => void;
  updateUser: (user: User) => void;
  isAdmin: () => boolean;
}

const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => ({
      user: null,
      isAuthenticated: false,
      login: async (email: string, password: string) => {
        try {
          const response = await login(email, password);
          if (!response?.user) {
            throw new Error('Invalid login response');
          }
          set({ user: response.user, isAuthenticated: true });
          return response.user;
        } catch (error) {
          console.error('Login error:', error);
          throw error;
        }
      },
      logout: () => {
        localStorage.clear(); // Clear all localStorage items
        delete api.defaults.headers.common['Authorization'];
        set({ user: null, isAuthenticated: false });
        window.location.href = '/login'; // Force redirect to login
      },
      updateUser: (user: User) => {
        set({ user, isAuthenticated: true });
      },
      isAdmin: () => {
        const state = get();
        return state.user?.role === 'admin';
      }
    }),
    {
      name: 'auth-storage',
      storage: createJSONStorage(() => localStorage),
      version: 1,
    }
  )
);

const AuthContext = createContext<AuthState | null>(null);

export function AuthProvider({ children }: { children: ReactNode }) {
  return (
    <AuthContext.Provider value={useAuthStore()}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export { useAuthStore };