import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { CustomToaster } from './components/ui/toaster'; // Corrected import
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Manifesto from './pages/Manifesto';
import FAQ from './pages/FAQ'; // Corrected import
import News from './pages/News';
import Contact from './pages/Contact';
import SubmitProject from './pages/SubmitProject';
import MemberArea from './pages/MemberArea';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import DataPolicy from './pages/DataPolicy';
import Footer from './components/Footer';
import WhatsAppButton from './components/WhatsAppButton';
import { useScrollTop } from './hooks/useScrollTop';
import { useGoogleAnalytics } from './hooks/useGoogleAnalytics';
import ConstructionBackground from './components/animations/ConstructionBackground';
import AdminDashboard from './components/AdminDashboard';
import { Navigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import { useEffect } from 'react';
import { useContentStore } from './stores/contentStore';

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredRole?: 'user' | 'admin';
}

function ProtectedRoute({ children, requiredRole }: ProtectedRouteProps) {
  const { user, isAuthenticated } = useAuth();
  
  // Not authenticated at all
  if (!isAuthenticated || !user) {
    return <Navigate to="/" replace />;
  }

  // Check for required role
  if (requiredRole && user.role !== requiredRole) {
    // If user is admin, allow access to user routes
    if (user.role === 'admin' && requiredRole === 'user') {
      return <>{children}</>;
    }
    return <Navigate to="/" replace />;
  }
  
  return <>{children}</>;
}

function AppContent() {
  useGoogleAnalytics();
  useScrollTop();

  const {
    home,
    about,
    manifesto,
    contact,
    faq,
    updateHome,
    updateAbout,
    updateManifesto,
    updateContact,
    updateFaq
  } = useContentStore();

  // Initialize content store with default values if not already set
  useEffect(() => {
    if (!home?.mainTitle) {
      updateHome({
        mainTitle: 'Orçamentação e Gestão de Custo',
        subtitle: 'A Visio está revolucionando o processo de orçamento de obras com sua inteligência personalizada de custos. Integramos a experiência de engenheiros e especialistas à tecnologia para oferecer uma estrutura orçamentária completa e de fácil entendimento para os nossos clientes.',
        statsTitle: 'Nossos Números',
        stats: [
          {
            value: '248',
            label: 'Projetos Orçados',
            icon: 'Building2'
          },
          {
            value: '98%',
            label: 'Clientes Satisfeitos',
            icon: 'BarChart3'
          },
          {
            value: '15+',
            label: 'Anos de Experiência',
            icon: 'LineChart'
          }
        ]
      });
    }

    if (!about?.title) {
      updateAbout({
        title: 'Nós empoderamos os nossos clientes',
        subtitle: 'A Visio é uma construtech que potencializa os resultados dos seus projetos de construção com inovação e tecnologia.',
      });
    }

    if (!manifesto?.title) {
      updateManifesto({
        title: 'Nosso Propósito',
        subtitle: 'Somos dedicados a revolucionar a indústria de construção civil por meio de inovação e tecnologia.',
      });
    }

    if (!contact?.title) {
      updateContact({
        title: 'Olá, Partner! 👋',
        subtitle: 'Entre em contato conosco para saber mais sobre nossos serviços.',
      });
    }

    if (!faq?.title) {
      updateFaq({
        title: 'Perguntas Frequentes',
        subtitle: 'Encontre respostas para as perguntas mais comuns sobre nossos serviços.',
        faqs: []
      });
    }
  }, [home, about, manifesto, contact, faq, updateHome, updateAbout, updateManifesto, updateContact, updateFaq]);

  return (
    <div className="flex flex-col min-h-screen relative">
      <div className="fixed inset-0 bg-black -z-10" />
      <ConstructionBackground />
      
      <Navbar />
      
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/manifesto" element={<Manifesto />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/news" element={<News />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/submit-project" element={<SubmitProject />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/data-policy" element={<DataPolicy />} />
          
          {/* Protected Routes */}
          <Route
            path="/member/*"
            element={
              <ProtectedRoute requiredRole="user">
                <MemberArea />
              </ProtectedRoute>
            }
          />
          
          <Route
            path="/admin/*"
            element={
              <ProtectedRoute requiredRole="admin">
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
        </Routes>
      </main>
      
      <Footer />
      <WhatsAppButton />
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <AppContent />
      <CustomToaster />
    </AuthProvider>
  );
}

export default App;