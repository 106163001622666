import { Dialog, DialogContent } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { CheckCircle2, Copy } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

interface SubmissionSuccessDialogProps {
  isOpen: boolean;
  onClose: () => void;
  reference: string;
  projectName?: string;
  clientName?: string;
  email?: string;
}

export default function SubmissionSuccessDialog({
  isOpen,
  onClose,
  reference,
  projectName,
  clientName,
  email
}: SubmissionSuccessDialogProps) {
  const [countdown, setCountdown] = useState(5);
  const navigate = useNavigate();

  const isLoggedIn = !!localStorage.getItem('token');

  useEffect(() => {
    if (isOpen) {
      const timer = setInterval(() => {
        setCountdown(prev => {
          if (prev <= 1) {
            clearInterval(timer);
            if (isLoggedIn) {
              navigate('/member/projects');
            } else if (email) {
              sessionStorage.setItem('pendingRegistrationEmail', email);
              navigate('/register');
            }
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [isOpen, navigate, email, isLoggedIn]);

  const getRedirectMessage = () => {
    if (isLoggedIn) {
      return `Você será redirecionado para seus projetos em ${countdown} segundos...`;
    }
    return `Você precisa se registrar para acompanhar o status do seu projeto. 
            Você será redirecionado em ${countdown} segundos...`;
  };

  const formatDate = (date: Date) => {
    return date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const formattedReference = reference || 'Projeto - Admin - ' + formatDate(new Date());

  const handleCopyReference = async () => {
    try {
      await navigator.clipboard.writeText(formattedReference);
    } catch (err) {
      console.error('Failed to copy reference:', err);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent 
        className="max-w-md text-center"
        aria-describedby="submission-success-description"
        aria-labelledby="submission-success-title"
      >
        <div className="space-y-6">
          <div className="flex flex-col items-center gap-4">
            <CheckCircle2 className="h-12 w-12 text-green-500" aria-hidden="true" />
            <h2 id="submission-success-title" className="text-2xl font-semibold">
              Projeto Enviado com Sucesso!
            </h2>
          </div>

          <p id="submission-success-description" className="text-white/60">
            {getRedirectMessage()}
          </p>

          <div className="space-y-2">
            <p className="text-sm text-white/70">Referência do Projeto:</p>
            <div className="flex items-center gap-2 p-3 bg-black/40 rounded-lg">
              <p className="flex-1 text-left font-medium">{formattedReference}</p>
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8"
                onClick={handleCopyReference}
              >
                <Copy className="h-4 w-4" />
              </Button>
            </div>
            <p className="text-sm text-white/60">
              Guarde esta referência para acompanhar o status do seu projeto.
            </p>
          </div>

          <div className="flex flex-col gap-2">
            <Button variant="ghost" onClick={onClose}>
              Fechar
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
