import { useState, useEffect } from 'react';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { FileText, Download, Search, Calendar, Filter, Phone, Mail, User } from 'lucide-react';
import { Project, ProjectFile } from '@/types/project';
import { formatDatePtBR } from '@/utils/dateFormat';
import { ProjectDetailsDialog } from '@/components/shared/ProjectDetailsDialog';
import { cn } from '@/lib/utils';

interface SubmissionsTabProps {
  projects: Project[];
}

export const SubmissionsTab = ({ projects }: SubmissionsTabProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [dateFilter, setDateFilter] = useState('all');
  const [statusFilter, setStatusFilter] = useState('all');
  const [filteredProjects, setFilteredProjects] = useState<Project[]>(projects);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);

  useEffect(() => {
    let result = [...projects].sort((a, b) => {
      const dateA = new Date(b.submittedAt || b.createdAt).getTime();
      const dateB = new Date(a.submittedAt || a.createdAt).getTime();
      return dateA - dateB;
    });

    // Apply search filter
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      result = result.filter(project => 
        project.submissionDetails?.name?.toLowerCase().includes(term) ||
        project.submissionDetails?.email?.toLowerCase().includes(term) ||
        project.submissionDetails?.description?.toLowerCase().includes(term) ||
        project.type?.toLowerCase().includes(term) ||
        project.status?.toLowerCase().includes(term)
      );
    }

    // Apply date filter
    if (dateFilter !== 'all') {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const projectDate = (project: Project) => new Date(project.submittedAt || project.createdAt);

      switch (dateFilter) {
        case 'today':
          result = result.filter(project => {
            const date = projectDate(project);
            return date >= today;
          });
          break;
        case 'week':
          const lastWeek = new Date(today);
          lastWeek.setDate(today.getDate() - 7);
          result = result.filter(project => {
            const date = projectDate(project);
            return date >= lastWeek;
          });
          break;
        case 'month':
          const lastMonth = new Date(today);
          lastMonth.setMonth(today.getMonth() - 1);
          result = result.filter(project => {
            const date = projectDate(project);
            return date >= lastMonth;
          });
          break;
      }
    }

    // Apply status filter
    if (statusFilter !== 'all') {
      result = result.filter(project => project.status === statusFilter);
    }

    setFilteredProjects(result);
  }, [projects, searchTerm, dateFilter, statusFilter]);

  return (
    <div className="space-y-6">
      {/* Filters */}
      <div className="grid gap-4 md:grid-cols-3">
        <div className="flex flex-col gap-2">
          <Label>Buscar</Label>
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
            <Input
              placeholder="Buscar por nome, email..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10"
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Label>Data</Label>
          <Select value={dateFilter} onValueChange={setDateFilter}>
            <SelectTrigger>
              <Calendar className="mr-2 h-4 w-4" />
              <SelectValue placeholder="Filtrar por data" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">Todas as datas</SelectItem>
              <SelectItem value="today">Hoje</SelectItem>
              <SelectItem value="week">Última semana</SelectItem>
              <SelectItem value="month">Último mês</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className="flex flex-col gap-2">
          <Label>Status</Label>
          <Select value={statusFilter} onValueChange={setStatusFilter}>
            <SelectTrigger>
              <Filter className="mr-2 h-4 w-4" />
              <SelectValue placeholder="Filtrar por status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">Todos os status</SelectItem>
              <SelectItem value="Enviado">Enviado</SelectItem>
              <SelectItem value="Em Análise">Em Análise</SelectItem>
              <SelectItem value="Concluído">Concluído</SelectItem>
              <SelectItem value="Cancelado">Cancelado</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      {/* Projects List */}
      <div className="grid gap-4">
        {filteredProjects.map((project) => (
          <Card key={project.id} className="p-4 bg-black/40 border-white/10">
            <div className="flex flex-col md:flex-row gap-4 justify-between">
              <div className="space-y-4">
                <div>
                  <h3 className="text-lg font-semibold mb-1">{project.reference || project.submissionDetails?.name || 'Sem nome'}</h3>
                  <div className="flex flex-wrap gap-4 text-sm text-white/60">
                    <div className="flex items-center gap-1">
                      <Mail className="h-4 w-4" />
                      {project.submissionDetails?.email}
                    </div>
                    <div className="flex items-center gap-1">
                      <Phone className="h-4 w-4" />
                      {project.submissionDetails?.phone}
                    </div>
                    <div className="flex items-center gap-1">
                      <Calendar className="h-4 w-4" />
                      {formatDatePtBR(project.submittedAt || project.createdAt)}
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap gap-2">
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => setSelectedProject(project)}
                    className="hover:bg-white/10"
                  >
                    <FileText className="h-4 w-4 mr-2" />
                    Ver Detalhes
                  </Button>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <div className={cn(
                  "px-3 py-1.5 text-sm font-medium rounded-full",
                  project.status === 'Enviado' && "bg-blue-500/20 text-blue-300",
                  project.status === 'Em Análise' && "bg-yellow-500/20 text-yellow-300",
                  project.status === 'Concluído' && "bg-green-500/20 text-green-300",
                  project.status === 'Cancelado' && "bg-red-500/20 text-red-300"
                )}>
                  {project.status}
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>

      {/* Project Details Dialog */}
      {selectedProject && (
        <ProjectDetailsDialog
          project={selectedProject}
          isOpen={!!selectedProject}
          onClose={() => setSelectedProject(null)}
          setProjects={setFilteredProjects}
        />
      )}
    </div>
  );
};

export default SubmissionsTab;
