import axios from 'axios';

// Environment setup
const isDevelopment = import.meta.env.DEV;

// API URL configuration
export const API_URL = isDevelopment 
  ? 'http://localhost:3001/api'  // Development API URL
  : 'https://www.visiopartners.com.br/api';  // Production API URL

// Create axios instance with base configuration
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Accept': 'application/json'
  },
  withCredentials: false, 
  timeout: 120000, // Increased timeout for file uploads
  maxContentLength: 50 * 1024 * 1024, // 50MB max content length
  maxBodyLength: 50 * 1024 * 1024, // 50MB max body length
  validateStatus: (status) => {
    return status >= 200 && status < 500; // Handle only server errors globally
  },
  onUploadProgress: (progressEvent) => {
    console.log('Upload Progress:', {
      loaded: progressEvent.loaded,
      total: progressEvent.total,
      progress: Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1))
    });
  }
});

// Add request interceptor for auth
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    
    // List of endpoints that don't require authentication
    const publicEndpoints = ['/login', '/register', '/projects/submit'];
    const isPublicEndpoint = publicEndpoints.some(endpoint => config.url?.endsWith(endpoint));
    
    if (!isPublicEndpoint && token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      console.log('Added token to request headers');
    }
    
    // Don't set Content-Type for FormData - browser will set it automatically with boundary
    if (config.data instanceof FormData) {
      delete config.headers['Content-Type'];
    } else {
      config.headers['Content-Type'] = 'application/json';
    }
    
    return config;
  },
  (error) => {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  }
);

// Add response interceptor for auth errors
api.interceptors.response.use(
  (response) => {
    console.log('Response received:', {
      url: response.config.url,
      status: response.status,
      data: response.data
    });
    return response;
  },
  (error) => {
    // Log detailed error information
    console.error('Response error:', {
      url: error.config?.url,
      status: error.response?.status,
      statusText: error.response?.statusText,
      data: error.response?.data,
      message: error.message,
      code: error.code,
      requestId: error.response?.data?.requestId
    });

    // Handle specific error types
    if (error.code === 'ECONNABORTED') {
      return Promise.reject(new Error('Tempo limite excedido. Por favor, tente novamente.'));
    }

    if (error.code === 'ERR_NETWORK') {
      return Promise.reject(new Error('Erro de conexão. Por favor, verifique sua internet.'));
    }

    // Handle form submission errors
    if (error.config?.url?.includes('/projects/submit')) {
      console.log('Project submission error:', error.response?.data);
      return Promise.reject(new Error(
        error.response?.data?.error || 
        'Erro ao enviar projeto. Por favor, verifique os dados e tente novamente.'
      ));
    }

    // Handle authentication errors
    if (error.response?.status === 401) {
      if (error.config?.url?.includes('/projects/submit')) {
        // Don't redirect for non-auth endpoints
        return Promise.reject(new Error(error.response?.data?.error || 'Erro ao enviar projeto'));
      }
      console.log('Unauthorized request - redirecting to login');
      localStorage.clear();
      delete api.defaults.headers.common['Authorization'];
      window.location.href = '/login';
      return Promise.reject(new Error('Sessão expirada. Por favor, faça login novamente.'));
    }

    if (error.response?.status === 403) {
      return Promise.reject(new Error('Acesso negado. Você não tem permissão.'));
    }

    // Return error message in Portuguese
    return Promise.reject(
      new Error(
        error.response?.data?.error || 
        'Ocorreu um erro. Por favor, tente novamente mais tarde.'
      )
    );
  }
);

export default api;
