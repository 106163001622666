import { Building, Cpu, Target } from 'lucide-react';
import { motion } from 'framer-motion';
import { useContentStore } from '@/stores/contentStore';
import { ScrollArrow } from '@/components/ScrollArrow';

const Manifesto = () => {
  const { manifesto } = useContentStore();

  if (!manifesto) return null;

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <section
        className="relative min-h-[60vh] flex items-center justify-center bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url('/assets/manifest.jpg')` }}
      >
        {/* Black fade for smooth transition */}
        <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-black to-transparent" />

        <div className="relative z-10 text-center max-w-6xl mx-auto px-4 mt-32 md:mt-16">
          <h1 
            className="text-[2.75rem] md:text-[3.5rem] leading-tight font-bold mb-3 max-w-6xl mx-auto text-white
              drop-shadow-[0_1px_1px_rgba(75,75,75,0.8)]
              [text-shadow:_0_1px_0_rgb(75_75_75_/_40%),_0_2px_0_rgb(75_75_75_/_30%),_0_3px_0_rgb(75_75_75_/_20%)]"
          >
            {manifesto.title}
          </h1>
          <p
            className="text-xl md:text-2xl text-white/90 max-w-6xl mx-auto mt-6
              drop-shadow-[0_1px_1px_rgba(75,75,75,0.8)]
              [text-shadow:_0_1px_0_rgb(75_75_75_/_40%)]"
          >
            {manifesto.subtitle}
          </p>
          <p className="text-white/70 mt-4 max-w-4xl mx-auto">
            {manifesto.description}
          </p>
        </div>
        <ScrollArrow />
      </section>
      
      {/* Features Section */}
      <section className="min-h-[90vh] bg-black relative">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,_var(--tw-gradient-stops))] from-white/[0.03] to-transparent" />
        
        {/* Content wrapper for vertical centering */}
        <div className="absolute inset-0 flex items-center">
          <div className="w-full max-w-7xl mx-auto px-8">
            <div className="grid md:grid-cols-3 gap-8">
              <div className="relative group">
                {/* Glow effect */}
                <div className="absolute -inset-1 bg-white/20 rounded-2xl blur-xl group-hover:bg-white/30 transition-colors duration-500" />
                
                {/* Glass card */}
                <div className="relative p-8 rounded-xl bg-white/10 backdrop-blur-lg
                  transform hover:scale-105 transition-all duration-300
                  shadow-[0_8px_32px_rgba(255,255,255,0.1)]
                  group-hover:shadow-[0_8px_32px_rgba(255,255,255,0.2)]
                  before:absolute before:inset-0 before:rounded-xl before:border-t before:border-l before:border-white/20 before:-z-[1] before:translate-y-0.5
                  after:absolute after:inset-0 after:rounded-xl after:border-b after:border-r after:border-black/20 after:-z-[1] after:-translate-y-0.5
                  border border-white/5 h-full">
                  <div className="h-8 w-8 mb-4">
                    <Building className="w-full h-full text-white" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2 text-white">Construção Civil</h3>
                  <p className="text-white/90">Acreditamos no potencial transformador da construção civil para a sociedade</p>
                </div>
              </div>

              <div className="relative group">
                {/* Glow effect */}
                <div className="absolute -inset-1 bg-white/20 rounded-2xl blur-xl group-hover:bg-white/30 transition-colors duration-500" />
                
                {/* Glass card */}
                <div className="relative p-8 rounded-xl bg-white/10 backdrop-blur-lg
                  transform hover:scale-105 transition-all duration-300
                  shadow-[0_8px_32px_rgba(255,255,255,0.1)]
                  group-hover:shadow-[0_8px_32px_rgba(255,255,255,0.2)]
                  before:absolute before:inset-0 before:rounded-xl before:border-t before:border-l before:border-white/20 before:-z-[1] before:translate-y-0.5
                  after:absolute after:inset-0 after:rounded-xl after:border-b after:border-r after:border-black/20 after:-z-[1] after:-translate-y-0.5
                  border border-white/5 h-full">
                  <div className="h-8 w-8 mb-4">
                    <Target className="w-full h-full text-white" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2 text-white">Propósito</h3>
                  <p className="text-white/90">Queremos tornar a construção civil mais eficiente e sustentável</p>
                </div>
              </div>

              <div className="relative group">
                {/* Glow effect */}
                <div className="absolute -inset-1 bg-white/20 rounded-2xl blur-xl group-hover:bg-white/30 transition-colors duration-500" />
                
                {/* Glass card */}
                <div className="relative p-8 rounded-xl bg-white/10 backdrop-blur-lg
                  transform hover:scale-105 transition-all duration-300
                  shadow-[0_8px_32px_rgba(255,255,255,0.1)]
                  group-hover:shadow-[0_8px_32px_rgba(255,255,255,0.2)]
                  before:absolute before:inset-0 before:rounded-xl before:border-t before:border-l before:border-white/20 before:-z-[1] before:translate-y-0.5
                  after:absolute after:inset-0 after:rounded-xl after:border-b after:border-r after:border-black/20 after:-z-[1] after:-translate-y-0.5
                  border border-white/5 h-full">
                  <div className="h-8 w-8 mb-4">
                    <Cpu className="w-full h-full text-white" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2 text-white">Inovação</h3>
                  <p className="text-white/90">Utilizamos tecnologia de ponta para revolucionar o setor</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Manifesto;