import api from './axios';

// Function to check if token is expired
const isTokenExpired = (token: string): boolean => {
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.exp * 1000 < Date.now();
  } catch {
    return true;
  }
};

// Add request interceptor for debugging and auth
api.interceptors.request.use(
  (config) => {
    // Only add token for non-public routes
    const publicRoutes = ['/login', '/register', '/projects/submit'];
    const isPublicRoute = publicRoutes.some(route => config.url?.endsWith(route));
    
    if (!isPublicRoute) {
      const token = localStorage.getItem('token');
      
      if (token) {
        // Check if token is expired
        if (isTokenExpired(token)) {
          console.warn('Token expired - redirecting to login');
          localStorage.removeItem('token');
          window.location.href = '/login';
          return Promise.reject(new Error('Token expired'));
        }
        
        config.headers['Authorization'] = `Bearer ${token}`;
        console.log('Added valid token to request headers');
      } else {
        console.warn('No token found for authenticated route');
        window.location.href = '/login';
        return Promise.reject(new Error('No authentication token'));
      }
    }
    
    return config;
  },
  (error) => {
    console.error('API Request Error:', error);
    return Promise.reject(error);
  }
);

// Add response interceptor to handle auth errors
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      console.log('Unauthorized request - clearing token and redirecting');
      localStorage.removeItem('token');
      
      // Only redirect if not already on login page
      if (!window.location.pathname.includes('/login')) {
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

// In-memory storage for local development
const localUsers = new Map();

// Initialize admin user
localUsers.set('admin@visiopartners.com.br', {
  uid: 'admin-uid',
  id: 'admin-id',
  name: 'Admin User',
  email: 'admin@visiopartners.com.br',
  password: 'VisioAdmin@2024',
  role: 'admin',
  createdAt: new Date().toISOString()
});

// Login function
export interface LoginResponse {
  user: any;
  token: string;
}

export const login = async (email: string, password: string): Promise<LoginResponse> => {
  try {
    const response = await api.post<LoginResponse>('/login', { email, password });
    const { token, user } = response.data;
    
    // Store token
    localStorage.setItem('token', token);
    
    // Set default auth header
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    
    return response.data;
  } catch (error) {
    console.error('Login API error:', error);
    throw error;
  }
};

// Register function
export const register = async (userData: {
  name: string;
  email: string;
  password: string;
  company?: string;
}): Promise<{ user: any; token: string }> => {
  try {
    console.log('Starting registration...', {
      name: userData.name,
      email: userData.email,
      hasCompany: !!userData.company
    });

    const response = await api.post('/register', userData, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    console.log('Registration successful:', {
      success: response.data.success,
      hasToken: !!response.data.token,
      hasUser: !!response.data.user
    });

    const { user, token } = response.data;
    
    if (!token || !user) {
      throw new Error('Resposta inválida do servidor');
    }

    localStorage.setItem('token', token);
    return { user, token };
  } catch (error: any) {
    console.error('Registration error:', {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message
    });

    // Handle specific error cases
    if (error.response?.status === 409) {
      throw new Error('Este email já está cadastrado');
    }

    if (error.response?.status === 400) {
      throw new Error(error.response.data.error || 'Dados inválidos');
    }

    throw new Error(
      error.response?.data?.error || 
      'Erro ao criar conta. Por favor, tente novamente.'
    );
  }
};

// Submit project function
export const submitProject = async (data: any) => {
  try {
    const response = await api.post('/projects', data);
    return response.data;
  } catch (error: any) {
    console.error('Project submission error:', error);
    throw new Error('Erro ao enviar projeto. Por favor, tente novamente.');
  }
};

// Submit project without authentication
export const submitProjectNonLogged = async (data: any) => {
  try {
    const response = await api.post('/projects/submit', {
      submissionDetails: data,
      files: data.files
    });
    return response.data;
  } catch (error: any) {
    console.error('Project submission error:', error);
    throw new Error('Erro ao enviar projeto. Por favor, tente novamente.');
  }
};

// Get projects function
export const getProjects = async (userId?: string, email?: string) => {
  try {
    const params = new URLSearchParams();
    if (userId) params.append('userId', userId);
    if (email) params.append('email', email);
    
    const response = await api.get(`/projects?${params.toString()}`);
    return response.data;
  } catch (error) {
    console.error('Get projects API error:', error);
    throw error;
  }
};

// Update password function
export const updatePassword = async ({ currentPassword, newPassword }: { currentPassword: string; newPassword: string }) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Não autorizado');
    }

    const response = await api.post(
      '/update-password',
      { currentPassword, newPassword }
    );

    return response.data;
  } catch (error: any) {
    console.error('Update password error:', error);
    if (error.response?.status === 401) {
      throw new Error('Senha atual incorreta');
    } else if (error.response?.status === 404) {
      throw new Error('Usuário não encontrado');
    } else if (error.response?.data?.error) {
      throw new Error(error.response.data.error);
    }
    throw new Error('Erro ao atualizar senha');
  }
};

// Update user info function
export const updateUserInfo = async ({ name, company }: { name: string; company?: string }) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Não autorizado');
    }

    const response = await api.put('/user/profile', { name, company });
    return response.data.user;
  } catch (error: any) {
    console.error('Update user info error:', error);
    if (error.response?.status === 404) {
      throw new Error('Usuário não encontrado');
    } else if (error.response?.data?.error) {
      throw new Error(error.response.data.error);
    }
    throw new Error('Erro ao atualizar informações');
  }
};

export default api;