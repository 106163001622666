import { NewsItem } from '@/types/content';

// Import news images
import aboutImage from '@/assets/images/about.jpg';
import aiConstructionImg from '@/assets/images/home.jpg';
import constructionInnovationImg from '@/assets/images/faq.jpg';
import engineeringTechImg from '@/assets/images/manifest.jpg';

const defaultImage = '/images/about.jpg';

export const defaultNews: NewsItem[] = [
  {
    id: "1",
    title: "IA Revoluciona a Construção Civil: O Futuro é Agora",
    subtitle: "Como a Inteligência Artificial está transformando o setor da construção",
    description: "Descubra como a IA está revolucionando a construção civil com aplicações práticas e resultados impressionantes.",
    content: `A inteligência artificial está redefinindo os paradigmas da construção civil, trazendo inovações 
    que prometem revolucionar o setor. Desde a otimização de projetos até a previsão de manutenção 
    preventiva, a IA está se tornando uma ferramenta indispensável para empresas que buscam 
    eficiência e precisão em seus projetos.

    Entre as principais aplicações, destacam-se:
    • Análise preditiva de riscos em tempo real
    • Otimização inteligente de cronogramas
    • Automação de processos repetitivos
    • Monitoramento contínuo de obras
    • Previsão de manutenção preventiva
    
    A Visio está na vanguarda dessa transformação, implementando soluções de IA que já demonstram 
    resultados significativos em projetos reais. Nossos sistemas de IA auxiliam na:
    
    • Análise de projetos e detecção de inconsistências
    • Estimativas precisas de custos e prazos
    • Otimização do uso de recursos
    • Redução de desperdícios
    
    O futuro da construção civil é digital, e a IA é a chave para desbloquear todo o potencial 
    desse setor vital para a economia.`,
    author: "Equipe Visio",
    date: new Date(2024, 0, 10).toISOString(),
    image: aiConstructionImg,
    imageUrl: aiConstructionImg,
    category: "Tecnologia",
    readTime: "5 min"
  },
  {
    id: "2",
    title: "Tecnologia Revoluciona Orçamentos na Construção",
    subtitle: "Novas ferramentas estão transformando a maneira como fazemos orçamentos",
    description: "Conheça as novas tecnologias que estão revolucionando o processo de orçamentação na construção civil.",
    content: `A era digital chegou definitivamente ao setor de orçamentos na construção civil. 
    Com o advento de novas tecnologias e software especializados, o processo de orçamentação 
    está se tornando mais preciso, rápido e confiável do que nunca.

    Benefícios das novas tecnologias:
    • Maior precisão nas estimativas de custos
    • Redução significativa no tempo de elaboração
    • Integração completa com sistemas BIM
    • Análise de cenários em tempo real
    • Comparação automática de fornecedores
    
    A Visio tem sido pioneira na implementação dessas tecnologias, oferecendo soluções que 
    combinam expertise humana com o poder do processamento digital. Nosso sistema permite:
    
    • Orçamentos detalhados em minutos
    • Atualização automática de preços
    • Simulações de diferentes cenários
    • Relatórios personalizados
    
    Com essas inovações, estamos transformando o que antes era um processo demorado e 
    propenso a erros em uma experiência eficiente e precisa.`,
    author: "Equipe Visio",
    date: new Date(2024, 0, 15).toISOString(),
    image: aboutImage,
    imageUrl: aboutImage,
    category: "Orçamentos",
    readTime: "4 min"
  },
  {
    id: "3",
    title: "O Impacto da Tecnologia na Engenharia Moderna",
    subtitle: "Como inovações tecnológicas estão melhorando a engenharia",
    description: "Explore como as novas tecnologias estão transformando a prática da engenharia e melhorando resultados.",
    content: `A engenharia está passando por uma transformação sem precedentes graças aos 
    avanços tecnológicos. Desde a concepção do projeto até a execução final, novas 
    ferramentas e metodologias estão revolucionando a forma como trabalhamos.

    Principais avanços tecnológicos:
    • Modelagem 3D avançada com precisão milimétrica
    • Simulações em tempo real de condições adversas
    • Integração total entre sistemas diferentes
    • Automação de processos repetitivos
    • Gestão digital de documentos
    
    A Visio está comprometida em trazer essas inovações para seus clientes, garantindo:
    
    • Projetos mais eficientes e sustentáveis
    • Redução significativa de erros
    • Melhor comunicação entre equipes
    • Documentação precisa e acessível
    
    O futuro da engenharia é digital, e estamos liderando essa transformação com 
    soluções inovadoras e resultados comprovados.`,
    author: "Equipe Visio",
    date: new Date(2024, 0, 20).toISOString(),
    image: engineeringTechImg,
    imageUrl: engineeringTechImg,
    category: "Engenharia",
    readTime: "4 min"
  }
];
