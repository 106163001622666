import { Dialog, DialogContent } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { CheckCircle2, Copy } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface NonLoggedSubmissionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  reference: string;
  email: string;
  onRegisterClick: () => void;
}

export default function NonLoggedSubmissionDialog({
  isOpen,
  onClose,
  reference,
  email,
  onRegisterClick
}: NonLoggedSubmissionDialogProps) {
  const [countdown, setCountdown] = useState(15);
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      const timer = setInterval(() => {
        setCountdown(prev => {
          if (prev <= 1) {
            clearInterval(timer);
            handleRedirect();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => {
        clearInterval(timer);
        if (countdown > 0) {
          handleRedirect();
        }
      };
    }
  }, [isOpen]);

  const handleRedirect = () => {
    sessionStorage.setItem('pendingRegistrationEmail', email);
    onClose();
    onRegisterClick();
  };

  const handleCopyReference = async () => {
    try {
      await navigator.clipboard.writeText(reference);
    } catch (err) {
      console.error('Failed to copy reference:', err);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent 
        className="max-w-md text-center"
        aria-describedby="non-logged-submission-description"
        aria-labelledby="non-logged-submission-title"
      >
        <div className="space-y-6">
          <div className="flex flex-col items-center gap-4">
            <CheckCircle2 className="h-12 w-12 text-green-500" aria-hidden="true" />
            <h2 id="non-logged-submission-title" className="text-2xl font-semibold">
              Projeto Enviado com Sucesso!
            </h2>
          </div>

          <div className="space-y-4">
            <p id="non-logged-submission-description" className="text-white/60">
              Para acompanhar o status do seu projeto, <span className="text-green-500 font-bold">é necessário criar uma conta.</span>
            </p>
            <p className="text-white/60">
              <span className="text-green-500 font-bold">Você será redirecionado para o registro</span> em {countdown} segundos...
            </p>
          </div>

          <div className="space-y-2">
            <p className="text-sm text-white/70">Referência do Projeto:</p>
            <div className="flex items-center gap-2 p-3 bg-black/40 rounded-lg">
              <p className="flex-1 text-left font-medium">{reference}</p>
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8"
                onClick={handleCopyReference}
              >
                <Copy className="h-4 w-4" />
              </Button>
            </div>
            <p className="text-sm text-white/60">
              Guarde esta referência para acompanhar o status do seu projeto.
            </p>
          </div>

          <div className="flex flex-col gap-2">
            <Button 
              onClick={handleRedirect}
              className="w-full"
            >
              Criar Conta Agora
            </Button>
            <Button 
              variant="ghost" 
              onClick={onClose}
            >
              Fechar
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
