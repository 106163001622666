import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { produce } from 'immer';
import type { ContentState, ContentSection, NewsItem, LegalContent } from '@/types/content';
import { Project } from '@/types/project';
import { defaultNews } from '@/data/defaultNews';

// Import images
import homeHero from '@/assets/images/home.jpg';
import aboutHero from '@/assets/images/about.jpg';
import manifestoHero from '@/assets/images/manifest.jpg';
import faqHero from '@/assets/images/faq.jpg';
import contactHero from '@/assets/images/contact.jpg';

const initialContent: ContentSection = {
  title: '',
  subtitle: '',
  description: '',
  bannerImage: '',
  heroImage: '',
  faqs: [],
};

const defaultContent = {
  home: {
    ...initialContent,
    mainTitle: 'Orçamentação e Gestão de Custo',
    subtitle: 'A Visio está revolucionando o processo de orçamento de obras com sua inteligência personalizada de custos. Integramos a experiência de engenheiros e especialistas à tecnologia para oferecer uma estrutura orçamentária completa e de fácil entendimento para os nossos clientes.',
    heroImage: homeHero,
    statsTitle: 'Nossos Números',
    stats: [
      {
        value: '248',
        label: 'Projetos Orçados',
        icon: 'Building2'
      },
      {
        value: '98%',
        label: 'Clientes Satisfeitos',
        icon: 'BarChart3'
      },
      {
        value: '15+',
        label: 'Anos de Experiência',
        icon: 'LineChart'
      }
    ]
  },
  news: defaultNews,
  about: {
    ...initialContent,
    title: 'Nós empoderamos os nossos clientes',
    subtitle: 'A Visio é uma construtech que potencializa os resultados dos seus projetos de construção com inovação e tecnologia.',
    heroImage: aboutHero
  },
  manifesto: {
    ...initialContent,
    title: 'Nosso Manifesto',
    subtitle: 'Conheça nossa missão e valores',
    heroImage: manifestoHero
  },
  faq: {
    ...initialContent,
    title: 'Perguntas Frequentes',
    subtitle: 'Tire suas dúvidas sobre nossos serviços',
    heroImage: faqHero,
    faqs: [
      {
        id: '1',
        question: 'O que é a Visio e como ela pode ajudar minha empresa?',
        answer: 'A Visio é uma construtech especializada em orçamentação e gestão de custos para a construção civil. Nossa missão é revolucionar o processo de orçamentos através de:\n\n• Tecnologia avançada de análise e processamento de dados\n• Inteligência artificial aplicada à construção civil\n• Equipe especializada com anos de experiência no mercado\n• Metodologia própria de gestão de custos\n\nAjudamos empresas a:\n• Reduzir custos operacionais em até 25%\n• Aumentar a precisão dos orçamentos\n• Otimizar processos de gestão\n• Tomar decisões mais assertivas baseadas em dados'
      },
      {
        id: '2',
        question: 'Como funciona o processo de orçamentação da Visio?',
        answer: 'Nosso processo de orçamentação é estruturado em etapas claras e objetivas:\n\n1. Análise Inicial:\n• Reunião de briefing\n• Levantamento de requisitos\n• Análise preliminar do projeto\n\n2. Desenvolvimento:\n• Análise detalhada dos projetos\n• Quantificação precisa\n• Composição de custos\n• Precificação atualizada\n\n3. Revisão e Entrega:\n• Verificação de qualidade\n• Validação com o cliente\n• Ajustes finais\n• Entrega do orçamento completo'
      },
      {
        id: '3',
        question: 'Quais tipos de projetos a Visio atende?',
        answer: 'A Visio atende uma ampla gama de projetos na construção civil:\n\n• Edificações Residenciais:\n  - Casas de alto padrão\n  - Condomínios residenciais\n  - Edifícios multifamiliares\n\n• Projetos Comerciais:\n  - Escritórios\n  - Shoppings\n  - Lojas e varejo\n\n• Obras Industriais:\n  - Galpões\n  - Fábricas\n  - Centros de distribuição\n\n• Infraestrutura:\n  - Obras viárias\n  - Saneamento\n  - Instalações especiais'
      },
      {
        id: '5',
        question: 'Quais são as vantagens de utilizar a Visio?',
        answer: 'As principais vantagens de trabalhar com a Visio incluem:\n\n• Tecnologia Avançada:\n  - Software proprietário de orçamentação\n  - Inteligência artificial para análise de dados\n  - Automação de processos repetitivos\n\n• Expertise Técnica:\n  - Equipe altamente qualificada\n  - Mais de 15 anos de experiência\n  - Conhecimento aprofundado do mercado\n\n• Resultados Comprovados:\n  - Redução média de 25% nos custos\n  - 98% de satisfação dos clientes\n  - Mais de 200 projetos realizados\n\n• Suporte Completo:\n  - Acompanhamento personalizado\n  - Atualizações constantes\n  - Consultoria especializada'
      },
      {
        id: '6',
        question: 'Como iniciar um projeto com a Visio?',
        answer: 'Iniciar um projeto com a Visio é simples e rápido:\n\n1. Contato Inicial:\n• Entre em contato pelo site ou telefone\n• Agende uma reunião inicial gratuita\n• Apresente seu projeto\n\n2. Análise e Proposta:\n• Avaliação das necessidades\n• Elaboração de proposta personalizada\n• Definição de escopo e prazos\n\n3. Início do Projeto:\n• Assinatura do contrato\n• Envio da documentação\n• Início imediato dos trabalhos\n\nEntre em contato hoje mesmo: contato@visiopartners.com.br ou (31) 99398-4114'
      },
      {
        id: '7',
        question: 'Que informações são necessárias para solicitar um orçamento?',
        answer: 'Para iniciar um orçamento, precisamos das seguintes informações:\n\n• Documentação Básica:\n  - Projetos arquitetônicos\n  - Projetos complementares (se disponíveis)\n  - Memorial descritivo\n\n• Informações do Projeto:\n  - Localização da obra\n  - Prazo previsto de execução\n  - Padrão de acabamento desejado\n\n• Especificações Técnicas:\n  - Materiais preferenciais\n  - Sistemas construtivos\n  - Requisitos especiais\n\nQuanto mais detalhada a informação, mais preciso será o orçamento.'
      },
      {
        id: '8',
        question: 'A Visio oferece suporte pós-entrega do orçamento?',
        answer: 'Sim, oferecemos suporte completo pós-entrega:\n\n• Acompanhamento:\n  - Reuniões de apresentação do orçamento\n  - Esclarecimento de dúvidas\n  - Ajustes e revisões\n\n• Serviços Adicionais:\n  - Atualização de preços\n  - Análise de alternativas\n  - Consultoria técnica\n\n• Suporte Contínuo:\n  - Canais de atendimento dedicados\n  - Equipe técnica disponível\n  - Atualizações periódicas'
      }
    ]
  },
  contact: {
    ...initialContent,
    title: 'Olá, Partner! 👋',
    subtitle: 'Entre em contato conosco',
    heroImage: contactHero
  },
  projects: [],
  terms: {
    title: 'Termos de Uso',
    content: ''
  },
  privacy: {
    title: 'Política de Privacidade',
    content: ''
  },
  dataPolicy: {
    title: 'Política de Dados',
    content: ''
  }
};

export const useContentStore = create<ContentState>()(
  persist(
    (set) => ({
      ...defaultContent,
      updateHome: (content) => set(produce((state) => { state.home = content; })),
      updateAbout: (content) => set(produce((state) => { state.about = content; })),
      updateManifesto: (content) => set(produce((state) => { state.manifesto = content; })),
      updateContact: (content) => set(produce((state) => { state.contact = content; })),
      updateFaq: (content) => set(produce((state) => { state.faq = content; })),
      updateNews: (news) => set(produce((state) => { state.news = news; })),
      updateProjects: (projects: Project[]) => set(produce((state) => { state.projects = projects; })),
      updateTerms: (content) => set(produce((state) => { state.terms = content; })),
      updatePrivacy: (content) => set(produce((state) => { state.privacy = content; })),
      updateDataPolicy: (content) => set(produce((state) => { state.dataPolicy = content; }))
    }),
    {
      name: 'content-storage',
      storage: createJSONStorage(() => localStorage)
    }
  )
);