import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';
import { Upload, FileText, X } from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import { submitProject, submitProjectNonLogged, getProjects } from '@/lib/project-service';
import { formatPhoneNumber } from '@/lib/utils';
import { useContentStore } from '@/stores/contentStore';
import SubmissionSuccessDialog from '@/components/SubmissionSuccessDialog';
import NonLoggedSubmissionDialog from '@/components/NonLoggedSubmissionDialog';
import { RegisterDialog } from '@/components/RegisterDialog';
import { toast } from 'sonner';
import { validateCPF, validateCNPJ, formatCPF, formatCNPJ, isCPF } from '@/lib/validation';

const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

interface FormData {
  name: string;
  email: string;
  phone: string;
  profile: string;
  documentNumber: string;
  customProfile: string;
  description: string;
  deliverables: string[];
  files: File[];
  type: string;
}

const SubmitProject = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { updateProjects } = useContentStore();
  const [files, setFiles] = useState<File[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [submittedEmail, setSubmittedEmail] = useState('');
  const [submittedReference, setSubmittedReference] = useState('');
  const [formData, setFormData] = useState<FormData>({
    name: user?.name || '',
    email: user?.email || '',
    phone: '',
    profile: '',
    documentNumber: '',
    customProfile: '',
    description: '',
    deliverables: [],
    files: [],
    type: 'project'
  });

  const profileOptions = [
    'Construtora',
    'Incorporadora',
    'Escritório de Arquitetura',
    'Escritório de Engenharia',
    'Outro'
  ];

  const deliverableOptions = [
    'Resumo Orçamentário',
    'Orçamento Detalhado',
    'Análise de Materiais',
    'Curva ABC',
    'Composições de Custo'
  ];

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(e.target.files || []);
    
    if (newFiles.length === 0) return;

    const updatedFiles = [...files, ...newFiles];
    if (updatedFiles.length > 15) {
      toast.error('Máximo de 15 arquivos permitidos');
      return;
    }

    const maxFileSize = 100 * 1024 * 1024; // 100MB
    const oversizedFiles = newFiles.filter(file => file.size > maxFileSize);
    if (oversizedFiles.length > 0) {
      toast.error('Alguns arquivos excedem o limite de 100MB por arquivo');
      return;
    }

    const totalSize = updatedFiles.reduce((acc, file) => acc + file.size, 0);
    if (totalSize > 100 * 1024 * 1024) {
        toast.error('Tamanho total dos arquivos excede 100MB');
      return;
    }

    const fileNames = new Set();
    const uniqueFiles = updatedFiles.filter(file => {
      if (fileNames.has(file.name)) {
        return false;
      }
      fileNames.add(file.name);
      return true;
    });

    setFiles(uniqueFiles);
    e.target.value = '';
  };

  const handleDocumentNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, '');
    
    if (value.length <= 11) {
      value = formatCPF(value);
    } else {
      value = formatCNPJ(value);
    }

    setFormData(prev => ({ ...prev, documentNumber: value }));
  };

  const validateDocument = (value: string): boolean => {
    const numericValue = value.replace(/\D/g, '');
    if (numericValue.length === 11) {
      return validateCPF(numericValue);
    } else if (numericValue.length === 14) {
      return validateCNPJ(numericValue);
    }
    return false;
  };

const onSubmit = async (data: FormData) => {
    // Validate document number before submission
    if (!validateDocument(data.documentNumber)) {
        toast.error('Número de documento inválido. Por favor, verifique e tente novamente.');
        return;
    }
    console.log('Submitting project data:', data); // Log the data being sent
    try {
      setIsSubmitting(true);
      const response = await submitProjectNonLogged({
        name: data.name,
        email: data.email,
        phone: data.phone,
        documentNumber: data.documentNumber,
        profile: data.profile,
        customProfile: data.customProfile,
        description: data.description,
        deliverables: data.deliverables,
        files: data.files,
        type: data.type
      });

      if (response.success) {
        setSubmittedReference(response.reference || '');
        setSubmittedEmail(data.email);
        setShowSuccess(true);
        
        setFormData({
          name: user?.name || '',
          email: user?.email || '',
          phone: '',
          profile: '',
          documentNumber: '',
          customProfile: '',
          description: '',
          deliverables: [],
          files: [],
          type: 'project'
        });
        setFiles([]);
      }
    } catch (error) {
      console.error('Error submitting project:', error);
      toast.error('Erro ao enviar projeto. Por favor, tente novamente.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const removeFile = (fileToRemove: File) => {
    setFiles(files.filter(file => file !== fileToRemove));
  };

  return (
    <div className="min-h-screen pt-32">
      <div className="container mx-auto px-4 py-12 max-w-4xl">
        <h1 className="text-4xl font-bold mb-12 text-center bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-300">
          Envie seu Projeto
        </h1>
        
        {user ? (
          <SubmissionSuccessDialog
            isOpen={showSuccess}
            onClose={() => setShowSuccess(false)}
            reference={submittedReference}
            clientName={formData.name}
          />
        ) : (
          <NonLoggedSubmissionDialog
            isOpen={showSuccess}
            onClose={() => setShowSuccess(false)}
            reference={submittedReference}
            email={submittedEmail}
            onRegisterClick={() => setShowRegister(true)}
          />
        )}
        
        <RegisterDialog
          open={showRegister}
          onOpenChange={setShowRegister}
        />
        
        <Card className="p-8 bg-black/20 border-white/10">
          <form onSubmit={(e) => { e.preventDefault(); onSubmit(formData); }} className="space-y-6">
            <div className="space-y-4">
              <div>
                <Label htmlFor="name">Nome Completo *</Label>
                <Input
                  id="name"
                  value={formData.name}
                  onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                  className="bg-black/20 border-white/10"
                  required
                />
              </div>

              <div>
                <Label htmlFor="email">Email *</Label>
                <Input
                  id="email"
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                  className="bg-black/20 border-white/10"
                  required
                  disabled={!!user}
                />
              </div>

              <div>
                <Label htmlFor="phone">Telefone *</Label>
                <Input
                  id="phone"
                  value={formData.phone}
                  onChange={(e) => {
                    const formatted = formatPhoneNumber(e.target.value);
                    setFormData(prev => ({ ...prev, phone: formatted }));
                  }}
                  placeholder="(00) 00000-0000"
                  className="bg-black/20 border-white/10"
                  required
                  maxLength={15}
                />
              </div>

              <div>
                <Label htmlFor="profile">Perfil *</Label>
                <select
                  id="profile"
                  value={formData.profile}
                  onChange={(e) => setFormData(prev => ({ ...prev, profile: e.target.value }))}
                  className="w-full p-2 rounded bg-black/20 border border-white/10 text-white/90"
                  required
                >
                  <option value="">Selecione um perfil</option>
                  {profileOptions.map((option) => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>

              {formData.profile === 'Outro' && (
                <div>
                  <Label htmlFor="customProfile">Especifique seu perfil *</Label>
                  <Input
                    id="customProfile"
                    value={formData.customProfile}
                    onChange={(e) => setFormData(prev => ({ ...prev, customProfile: e.target.value }))}
                    className="bg-black/20 border-white/10"
                    required
                  />
                </div>
              )}

              <div>
                <Label htmlFor="documentNumber">CNPJ/CPF *</Label>
                <Input
                  id="documentNumber"
                  value={formData.documentNumber}
                  onChange={handleDocumentNumberChange}
                  placeholder={isCPF(formData.documentNumber) ? "000.000.000-00" : "00.000.000/0000-00"}
                  className="bg-black/20 border-white/10"
                  required
                  maxLength={18}
                />
              </div>

              <div>
                <Label htmlFor="description">Descrição do Projeto *</Label>
                <Textarea
                  id="description"
                  value={formData.description}
                  onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                  className="bg-black/20 border-white/10 min-h-[120px]"
                  placeholder="Descreva seu projeto..."
                  required
                />
              </div>

              <div className="space-y-4">
                <Label>O que você gostaria de receber? *</Label>
                <div className="grid sm:grid-cols-2 gap-4">
                  {deliverableOptions.map((option) => (
                    <label
                      key={option}
                      className="flex items-center space-x-2 cursor-pointer"
                    >
                      <Checkbox
                        checked={formData.deliverables.includes(option)}
                        onCheckedChange={(checked) => {
                          if (checked) {
                            setFormData(prev => ({
                              ...prev,
                              deliverables: [...prev.deliverables, option]
                            }));
                          } else {
                            setFormData(prev => ({
                              ...prev,
                              deliverables: prev.deliverables.filter(
                                (item) => item !== option
                              )
                            }));
                          }
                        }}
                      />
                      <span>{option}</span>
                    </label>
                  ))}
                </div>
              </div>

              <div className="space-y-4">
                <Label>Anexar Arquivos</Label>
                <div className="grid gap-4">
                  <div
                    className={`
                      border-2 border-dashed rounded-lg p-8
                      ${files.length === 0 ? 'border-white/10' : 'border-white/20'}
                      hover:border-white/30 transition-colors
                      bg-white/5 relative
                    `}
                  >
                    <input
                      type="file"
                      onChange={handleFileChange}
                      className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                      multiple
                    />
                    <div className="text-center">
                      <Upload className="mx-auto h-8 w-8 mb-2 text-white/50" />
                      <p className="text-sm text-white/70">
                        Arraste arquivos ou clique para selecionar
                      </p>
                      <p className="text-xs text-white/50 mt-1">
                        Máximo de 15 arquivos (total de 50MB)
                      </p>
                    </div>
                  </div>

                  {files.length > 0 && (
                    <div className="space-y-2">
                      {files.map((file, index) => (
                        <div
                          key={index}
                          className="flex items-center justify-between p-3 bg-white/5 rounded-lg"
                        >
                          <div className="flex items-center space-x-3">
                            <FileText className="h-5 w-5 text-white/70" />
                            <div>
                              <p className="text-sm font-medium">{file.name}</p>
                              <p className="text-xs text-white/50">
                                {formatFileSize(file.size)}
                              </p>
                            </div>
                          </div>
                          <Button
                            type="button"
                            variant="ghost"
                            size="icon"
                            onClick={() => removeFile(file)}
                          >
                            <X className="h-4 w-4" />
                          </Button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <Button
                type="submit"
                disabled={isSubmitting}
                className="w-full"
              >
                {isSubmitting ? 'Enviando...' : 'Enviar Projeto'}
              </Button>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default SubmitProject;
